<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div><router-link to="/Admin/fuw_class/form"><el-button type="primary" icon="el-icon-plus">添加</el-button></router-link></div>
                    <!-- <div><el-input v-model="name" placeholder="请输入内容"></el-input></div>
                    <div><el-button icon="el-icon-search">条件筛选</el-button></div> -->
                </div>

                <div class="admin_main_block_right">
                    <div><el-button type="danger" icon="el-icon-delete" @click="del(select_id)">批量删除</el-button></div>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table :data="list" @selection-change="handleSelectionChange" row-key="id" :tree-props="{children: 'children',hasChildren:'hasChildren'}">
                    <el-table-column type="selection"></el-table-column>
                    <!-- <el-table-column prop="id" label="#" fixed="left" width="70px"></el-table-column> -->
                    <el-table-column  label="#"  width="70px"></el-table-column>
                    <el-table-column label="栏目名称">
                        <template slot-scope="scope">
                            <dl class="table_dl">
                                <dt><el-image style="width: 50px; height: 50px" :src="scope.row.goods_master_image"><div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div></el-image></dt>
                                <dd class="table_dl_dd_all">{{ scope.row.name }}</dd>
                            </dl>
                        </template>
                    </el-table-column>
                    <el-table-column label="推荐小程序首页">
                        <template slot-scope="scope">
                            <div :class="scope.row.is_index==1?'green_round':'gray_round'" @click="class_status(scope.row.id)"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="rate" label="分佣">
                        <template slot-scope="scope">
                            {{ scope.row.rate }} %
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_sort" label="排序"></el-table-column>
                    <el-table-column label="操作" fixed="right" width="120px">
                        <template slot-scope="scope">
                            <el-button icon="el-icon-edit" @click="$router.push({name:'fuw_class_form',params:{id:scope.row.id}})">编辑</el-button>
                            <!-- <el-button type="danger" icon="el-icon-delete" @click="del(scope.row.id)">删除</el-button> -->
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          list:[],
          select_id:'',
      };
    },
    watch: {},
    computed: {},
    methods: {
        class_status:function(id){
            this.$post(this.$api.editClassStatus,{id:id}).then(res=>{
                if(res.code==200){
                    this.$message.success('操作成功');
                }else{
                    this.$message.success('操作失败');
                }
                this.get_goods_class_list();
            });
        },
        handleSelectionChange:function(e){
            let ids = [];
            e.forEach(v => {
                ids.push(v.id);
            });
            this.select_id = ids.join(',');
        },
        get_goods_class_list:function(){
            this.$get(this.$api.getFuwClassList).then(res=>{
                this.list = res.data;
            })

        },
        // 删除处理
        del:function(id){
            if(this.$isEmpty(id)){
                return this.$message.error('请先选择删除的对象');
            }
            this.$post(this.$api.delFuwClass,{id:id}).then(res=>{
                if(res.code == 200){
                    this.get_goods_class_list();
                    return this.$message.success("删除成功");
                }else{
                    return this.$message.error("删除失败");
                }
            });
        }
    },
    created() {
        this.get_goods_class_list();
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
</style>